import { IRange } from "@/interfaces/range";
import { addDays, format, isAfter, isBefore, isWithinInterval } from "date-fns";
import { ptBR } from "date-fns/locale";

export const STABLE_WEEK_INDEX = 3;
export const STABLE_WEEKDAY_INDEX = 3;

export interface IWeekday {
	day: string;
	weekday: string;
	relatedMonth: "current" | "previous" | "next";
	isSelected?: boolean;
	isWorkDay?: boolean;
	year?: number;
	realMonthIndex: number;
	translatedMonth?: string;
	month?: string;
	isDisabled?: boolean;
}

export const weekdays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
export const translatedMonths = [
	"Janeiro",
	"Fevereiro",
	"Março",
	"Abril",
	"Maio",
	"Junho",
	"Julho",
	"Agosto",
	"Setembro",
	"Outubro",
	"Novembro",
	"Dezembro",
];

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const getLastDayFromMonth = (monthIndex: number, year = currentYear) => new Date(year, monthIndex + 1, 0).getDate();
export const getWeekdayFromMonth = (monthIndex: number, day: number, year = currentYear) => {
	const date = new Date(year, monthIndex, day);
	return { weekday: date.getDay(), year: date.getFullYear(), monthIndex: date.getMonth() };
};

export function getDaysFromMonth(monthIndex: number, year = currentYear) {
	const month: any[] = [[]];

	const data = getWeekdayFromMonth(monthIndex, 1, year);
	const { year: actualYear, monthIndex: actualMonthIndex } = data;

	const startDayOfMonth = data.weekday;
	const lastDayFromPreviousMonth = getLastDayFromMonth(monthIndex - 1);
	const lastDayFromThisMonth = getLastDayFromMonth(actualMonthIndex, actualYear);
	const lastWeekdaysOffset = 6 - getWeekdayFromMonth(monthIndex, lastDayFromThisMonth).weekday;
	let actualDayInMonthView = lastDayFromPreviousMonth - startDayOfMonth + 1;

	let currentWeek = 0;
	let weekdayIndex = 0;
	let relatedMonth: "current" | "previous" | "next" = "previous";

	for (let index = 0, length = lastDayFromThisMonth + startDayOfMonth + lastWeekdaysOffset; index < length; index++) {
		if (actualDayInMonthView === lastDayFromPreviousMonth + 1 && currentWeek === 0) {
			//..., 31, 01, 02...
			relatedMonth = "current";
			actualDayInMonthView = 1;
		} else if (actualDayInMonthView === lastDayFromThisMonth + 1 && currentWeek !== 0) {
			//..., 25, 26, 27, 28, 01...
			relatedMonth = "next";
			actualDayInMonthView = 1;
		}
		const weekday = weekdays[weekdayIndex];
		const weekdayObject: IWeekday = {
			weekday,
			isSelected: false,
			day: String(actualDayInMonthView).padStart(2, "0"),
			isWorkDay: ![0, 6].includes(weekdayIndex),
			relatedMonth,
			month: String(actualMonthIndex + 1).padStart(2, "0"),
			translatedMonth: translatedMonths[actualMonthIndex],
			realMonthIndex: actualMonthIndex,
			year: actualYear,
		};

		month[currentWeek].push(weekdayObject);
		weekdayIndex++;

		if (weekdayIndex === 7 && index + 1 !== length) {
			month.push([]);
			currentWeek++;
			weekdayIndex = 0;
		}

		actualDayInMonthView++;
	}

	return month;
}

function capitalize(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function readableDay(dateParam: string) {
	const hasOnlyDate = !dateParam.split("T")?.[1];
	const date = new Date(`${dateParam}${hasOnlyDate ? "T00:00" : ""}`);
	return `${capitalize(format(date, "EEE", { locale: ptBR }))}, ${capitalize(
		format(date, "dd", {
			locale: ptBR,
		}),
	)} de ${capitalize(format(date, "MMMM", { locale: ptBR }))}`;
}

export function formatDateToISO(dateParam: string) {
	const hasOnlyDate = !dateParam.split("T")?.[1];
	const date = `${dateParam}${hasOnlyDate ? "T03:00:00Z" : ""}`;

	return date;
}

export function findNextWorkDayAfterDays(dateParam: string, range: number) {
	const date = new Date(dateParam + "T00:00:00");
	let desiredDate = addDays(date, range);
	while ([0, 6].includes(desiredDate.getDay())) {
		range++;
		desiredDate = addDays(date, range);
	}

	return toIsoDate(desiredDate);
}

export function toIsoDate(date: Date) {
	return format(date, "yyyy-MM-dd");
}

export function isBetween(dateStr: string, { start, end }: IRange) {
	const date = new Date(dateStr);
	return isWithinInterval(date, { start: new Date(start), end: new Date(end) });
}
