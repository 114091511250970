



















import { Component, Prop, Vue } from "vue-property-decorator";
import { secondaryBody, primaryOrangeBody, secondaryOrangeBody, tertiaryBody } from "@/helpers/styleClassHelpers";

@Component({})
export default class CustomCheckbox extends Vue {
	@Prop({ default: "" }) label!: string;
	@Prop({ default: "" }) labelStyle!: string;
	@Prop({ default: "" }) error!: string;
	@Prop({ default: "" }) value!: string | any[];
	@Prop({ default: "" }) inputValue!: string;
	@Prop({ default: null }) index!: number;
	@Prop({ default: false }) isReadOnly!: boolean;
	@Prop({ default: (event: Event) => null }) onChange!: (event: Event, value: any) => void;

	tertiaryBody = tertiaryBody;
	secondaryBody = secondaryBody;
	primaryOrangeBody = primaryOrangeBody;
	secondaryOrangeBody = secondaryOrangeBody;

	inputChange(event: Event) {
		if (this.onChange && typeof this.onChange === "function") {
			this.onChange(event, this.value);
		}
	}

	get checkboxIcon() {
		if (this.error) {
			return "checkbox-error.svg";
		}
		if ((this.value.length && this.value.includes(this.inputValue)) || this.value == this.inputValue) {
			return "checked-checkbox.svg";
		}

		return "unchecked-checkbox.svg";
	}

	get model() {
		return this.value;
	}

	set model(value) {
		this.$emit("input", value);
	}
}
