import { INeed } from "./need";
import { BaseEntity } from "@/entities/base_entity";
import { Client } from "@/entities/client";
import { IScheduling } from "./scheduling";
import { IUser } from "./user";
import { ISolution } from "./solution";

export class AppointmentModality {
	static readonly IN_PERSON = "IN_PERSON";
	static readonly TELEMEDICINE = "TELEMEDICINE";
}

export enum AppointmentStatus {
	CONFIRMED = "CONFIRMED",
	COMPLETED = "COMPLETED",
	FAILED = "FAILED",
}

export interface IScheduleUpdateLog {
	userFullName: string;
	startDateTime: string | Date;
}

export class IAppointment extends BaseEntity<IAppointment> {
	scheduling?: IScheduling;
	client: Client;
	user: IUser;
	status: AppointmentStatus;
	need: INeed;
	solution: ISolution;
	returnDate?: Date | string;
	ratingValue?: number;
	reason?: string;
	description?: string;
	modality?: AppointmentModality;
	scheduleUpdatesLog?: IScheduleUpdateLog[];
	reportedNeedName?: string;
}

export type Days =
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| 13
	| 14
	| 15
	| 16
	| 17
	| 18
	| 19
	| 20
	| 21
	| 22
	| 23
	| 24
	| 25
	| 26
	| 27
	| 28
	| 29
	| 30
	| 31;

export const APPOINTMENTS_PER_DAY = {
	[1]: 0,
	[2]: 0,
	[3]: 0,
	[4]: 0,
	[5]: 0,
	[6]: 0,
	[7]: 0,
	[8]: 0,
	[9]: 0,
	[10]: 0,
	[11]: 0,
	[12]: 0,
	[13]: 0,
	[14]: 0,
	[15]: 0,
	[16]: 0,
	[17]: 0,
	[18]: 0,
	[19]: 0,
	[20]: 0,
	[21]: 0,
	[22]: 0,
	[23]: 0,
	[24]: 0,
	[25]: 0,
	[26]: 0,
	[27]: 0,
	[28]: 0,
	[29]: 0,
	[30]: 0,
	[31]: 0,
};
