var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"modalStyle":'relative',"isOpen":_vm.isOpen,"onBackButton":_vm.onBackButton,"withBackButton":true,"onModalClosed":_vm.onModalClosed}},[_c('div',{staticClass:"default-modal-enclosure-size h-auto flex flex-col gap-8"},[_c('Calendar',{attrs:{"isOpen":_vm.isReturnDateCalendarOpen,"isModalLike":true,"calendarEnclosureStyle":'w-2/3',"preventNonWorkDays":true},on:{"close":function () { return (_vm.isReturnDateCalendarOpen = false); }},model:{value:(_vm.selectedReturnDate),callback:function ($$v) {_vm.selectedReturnDate=$$v},expression:"selectedReturnDate"}}),(_vm.isConfirmed && !_vm.isSimpleEditView)?_c('UserCard',{attrs:{"cardData":_vm.user,"cardHeaders":_vm.header,"customClass":'flex flex-row justify-start gap-x-4 items-center'}}):_vm._e(),_c('div',[_c('h1',{staticClass:"flex items-center justify-between mb-1 font-bold text-journey-black text-xl"},[_c('span',[_vm._v(" "+_vm._s(_vm.solution.name)+" ")]),_c('span',{staticClass:"text-journey-emerald-green font-bold text-sm flex gap-1 items-center"},[[(_vm.solution.ratingValueAverage)?[_vm._v(_vm._s(_vm.solution.ratingValueAverage.toFixed(1)))]:[_vm._v("-")]],_c('img',{attrs:{"src":require('../../assets/icons/green-star.svg'),"alt":"rating"}})],2)]),_c('p',{staticClass:"text-journey-black"},[_vm._v(" "+_vm._s(_vm.visualData)+" ")]),_c('div',{staticClass:"flex items-center justify-between"},[(_vm.solution.phoneNumber)?_c('div',{staticClass:"flex"},[_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"src":require('../../assets/icons/phone.svg'),"alt":"phone"}}),_c('span',[_vm._v(_vm._s(_vm.solution.phoneNumber))])]):_vm._e(),_c('div',{staticClass:"flex items-center gap-3 cursor-pointer",on:{"click":function () { return _vm.copySolution([
								_vm.readableDay(_vm.selectedAvailability || _vm.preSelectedAvailability, _vm.solution.category.name),
								_vm.readableSelectedTime(_vm.selectedAvailability || _vm.preSelectedAvailability, false),
								_vm.modalities[_vm.previousSelectedModalityIndex].name ]); }}},[_c('img',{attrs:{"src":require('../../assets/icons/copy-icon.svg'),"alt":"copy"}}),_c('span',{staticClass:"font-bold"},[_vm._v("Copiar Solução")])])])]),(!_vm.isConfirmed)?[(!_vm.hasAvailability)?_c('div',{staticClass:"mt-2 flex justify-center",staticStyle:{"min-height":"2rem"}},[_c('p',{staticClass:"m-0 h-full"},[_vm._v(" Não há horários disponíveis. "+_vm._s(_vm.solution.isFavorite ? "Solicitar vaga para o administrador da agenda." : "")+" ")])]):_vm._e(),_c('div',[_c('Calendar',{ref:"availabilityCalendar",attrs:{"hidden":!_vm.hasAvailability,"isWeekView":true,"isOpen":true,"highlightedDates":new Set(_vm.currentDates),"disabledDateRanges":[
						{
							start: '1970-01-01',
							end: _vm.todayIsoDate,
						} ]},on:{"calendar-section-change":_vm.searchAvailabilities},scopedSlots:_vm._u([{key:"header",fn:function(ref){
					var timeData = ref.timeData;
return [_c('div',{staticClass:"flex flex-col gap-1"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('span',[_vm._v(" Disponibilidade")]),_c('div',{staticClass:"flex items-center gap-4 text-journey-gray font-bold"},_vm._l((_vm.modalities),function(ref,index){
					var icon = ref.icon;
					var name = ref.name;
					var isSelected = ref.isSelected;
return _c('GuideBalloon',{key:icon,attrs:{"icon":("" + (isSelected ? 'selected-' : '') + icon),"iconStyle":("h-5 filter " + (isSelected ? 'opacity-100' : 'opacity-70')),"text":name,"isStaticBalloon":true},on:{"click":function () { return _vm.selectModality(index); }}})}),1)]),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"text-journey-gray font-normal"},[_vm._v(" "+_vm._s(((timeData.translatedMonth) + ", " + (timeData.year)))+" ")]),(_vm.isSimpleEditView)?_c('div',{staticClass:"text-journey-gray"},[_c('div',{staticClass:"align-middle rounded-full inline-block bg-journey-gray w-0.5 h-0.5",staticStyle:{"margin":"0 5px"}}),_c('span',{staticClass:"font-normal"},[_vm._v(" Horário atual ")]),_c('div',{staticClass:"align-middle rounded-full inline-block bg-journey-gray w-0.5 h-0.5",staticStyle:{"margin":"0 5px"}}),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.readableSelectedTime(_vm.selectedAvailability || _vm.preSelectedAvailability, false))+" ")])]):_vm._e()])])]}}],null,false,154229203),model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),(_vm.hasAvailability)?_c('div',[_c('h2',{staticClass:"flex items-center gap-2 text-journey-black text-base font-bold"},[_c('img',{attrs:{"src":require('../../assets/icons/schedule.svg'),"alt":"schedule"}}),_c('span',[_vm._v("Horários disponíveis")])]),_c('div',{staticClass:"mt-2 flex justify-center",staticStyle:{"min-height":"2rem"}},[(_vm.selectedDate)?_c('div',{staticClass:"flex justify-center gap-x-10 gap-y-3 flex-wrap"},_vm._l((_vm.currentAvailabilities),function(availability,index){return _c('GuideBalloon',{key:availability.id,attrs:{"text":_vm.getHourGuideBalloonText(availability),"isStaticBalloon":true,"iconStyle":'h-full',"staticOffset":{ x: 40, y: -45 }},on:{"click":function () { return _vm.selectHour(availability, index); }}},[_c('div',{staticClass:"mt-0 text-journey-black px-1 rounded-sm font-bold border-1 border-transparent",class:[
									//  && !this.isAvailabilityPreScheduledByCurrentUser(availability)
									availability.appointment ? 'border-journey-gray text-journey-gray' : 'cursor-pointer',
									{
										'border-journey-emerald-green text-journey-emerald-green':
											availability.id === (_vm.selectedAvailability && _vm.selectedAvailability.id),
									} ]},[_vm._v(" "+_vm._s(_vm.getHour(availability.startDateTime))+" ")])])}),1):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.currentAvailabilities.length),expression:"!currentAvailabilities.length"}],staticClass:"m-0 h-full"},[_vm._v(" "+_vm._s(!_vm.selectedDate ? "Selecione um dia." : "Nenhum horário disponível.")+" ")])])]):_vm._e(),(_vm.isSimpleEditView && _vm.isAtSchedulesPage)?_c('div',{staticClass:"flex justify-between items-center"},[_c('button',{staticClass:"text-journey-black",class:_vm.hasReturnDate ? 'border-b-1 border-journey-gray' : 'pointer-events-none border-b-0',on:{"click":function () { return (_vm.isReturnDateCalendarOpen = true); }}}),_c('Checkbox',{attrs:{"label":'Sem retorno'},model:{value:(_vm.hasReturnDate),callback:function ($$v) {_vm.hasReturnDate=$$v},expression:"hasReturnDate"}})],1):_vm._e()]:_c('div',[_c('h1',{staticClass:"font-bold text-journey-black text-xl"},[_vm._v("Consulta")]),_c('div',{staticClass:"flex items-start gap-4 mt-1"},[_c('img',{staticStyle:{"margin-top":"0.225rem"},attrs:{"src":require('../../assets/icons/schedule.svg'),"alt":"schedule"}}),_c('div',{staticClass:"text-journey-black"},[_c('p',[_vm._v(_vm._s(_vm.readableDay(_vm.selectedAvailability)))]),_c('p',[_vm._v(_vm._s(_vm.readableHour))])])])]),(_vm.hasAvailability)?_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"flex items-center gap-3 cursor-pointer",on:{"click":function () { return _vm.copySolution([
							_vm.readableDay(_vm.selectedAvailability || _vm.initialAvailability),
							_vm.readableSelectedTime(_vm.selectedAvailability || _vm.initialAvailability, false),
							_vm.modalities[_vm.previousSelectedModalityIndex].name ]); }}},[_c('img',{attrs:{"src":require('../../assets/icons/copy-icon.svg'),"alt":"copy"}}),_c('span',{staticClass:"font-bold"},[_vm._v("Copiar Solução")])]),_c('p',{staticClass:"font-bold m-0"},[_vm._v("Lembre-se de copiar a solução, enviar ao cliente e salvar a consulta")])]):_vm._e(),_c('Button',{staticClass:"bg-journey-emerald-green mx-auto py-1",staticStyle:{"width":"70%"},attrs:{"disabledStyle":'pointer-events-none',"loading":_vm.isLoading},on:{"click":_vm.createOrUpdateAppointment}},[_vm._v(_vm._s(_vm.buttonText)+" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }