























import { Component, Prop, Vue } from "vue-property-decorator";
import { BSpinner } from "bootstrap-vue";

export type ButtonVariant =
	| "primary"
	| "secondary"
	| "primaryDisabled"
	| "secondaryDisabled"
	| "secondaryCancel"
	| "secondaryConfirm"
	| "tertiary"
	| "tertiaryCancel"
	| "quaternary"
	| "quaternaryReverse"
	| "quintenary";
type ButtonType = "button" | "submit";

@Component({
	components: {
		BSpinner,
	},
})
export default class CustomButton extends Vue {
	@Prop() disabledStyle!: string;
	@Prop({ default: "primary" }) variant!: ButtonVariant;
	@Prop({ default: "auto" }) width!: string;
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event, buttonAction?: string) => void;
	@Prop({ default: "button" }) type!: ButtonType;
	@Prop({ default: "" }) buttonAction!: string;
	@Prop({ default: "" }) buttonStyle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconStyle!: string;
	@Prop({ default: false }) loading!: boolean;

	baseCss = "rounded-lg focus:outline-none";
	primaryCss = "bg-journey-green text-journey-white hover:shadow-md transition-all duration-300 font-bold";
	primaryDisabledCss =
		"rounded-lg focus:outline-none bg-journey-gray text-journey-white hover:shadow-md transition-all duration-300";
	secondaryCss =
		"bg-journey-white text-journey-green border-1 border-journey-green hover:shadow-md transition-all duration-300";
	secondaryDisabledCss =
		"rounded-lg focus:outline-none bg-journey-white text-journey-gray border border-journey-gray hover:shadow-md transition-all duration-300";
	secondaryCancelCss =
		"bg-journey-white text-journey-orange border-1 border-journey-orange hover:shadow-md transition-all duration-300";
	tertiaryCancelCss =
		"bg-journey-white text-journey-red border-1 border-journey-red hover:shadow-md transition-all duration-300";
	secondaryConfirmCss =
		"bg-journey-white text-journey-green border-1 border-journey-green hover:shadow-md transition-all duration-300";
	tertiaryCss = "text-journey-black font-bold";
	quaternaryCss = "text-journey-black border-1 border-journey-light-green";
	quaternaryReverseCss = "text-journey-black bg-journey-light-green font-bold";
	quintenaryCss = "text-journey-orange whitespace-nowrap font-bold";

	get buttonVariant() {
		let baseStyles = [this.baseCss];

		if (this.buttonStyle) {
			baseStyles.push(this.buttonStyle);
		} else {
			switch (this.variant) {
				case "primary":
					baseStyles.push(this.primaryCss);
					break;
				case "secondary":
					baseStyles.push(this.secondaryCss);
					break;
				case "secondaryCancel":
					baseStyles.push(this.secondaryCancelCss);
					break;
				case "tertiaryCancel":
					baseStyles.push(this.tertiaryCancelCss);
					break;
				case "secondaryConfirm":
					baseStyles.push(this.secondaryConfirmCss);
					break;
				case "tertiary":
					baseStyles.push(this.tertiaryCss);
					break;
				case "quaternary":
					baseStyles.push(this.quaternaryCss);
					break;
				case "quaternaryReverse":
					baseStyles.push(this.quaternaryReverseCss);
					break;
				case "quintenary":
					baseStyles.push(this.quintenaryCss);
					break;
			}
		}

		if ((this.disabled && this.variant === "primary") || (this.disabled && this.variant === "quaternaryReverse")) {
			baseStyles = [this.primaryDisabledCss];
		} else if ((this.disabled && this.variant === "secondary") || (this.disabled && this.variant === "quaternary")) {
			baseStyles = [this.secondaryDisabledCss];
		} else if (this.disabled && !this.variant.includes("primary" || "secondary" || "quarternary")) {
			baseStyles.push("bg-opacity-50 opacity-50 border-opacity-20");
		}

		return baseStyles;
	}

	handleClick(event: Event) {
		if (this.disabled) {
			return;
		}
		event.preventDefault();
		if (this.onClick && typeof this.onClick === "function") {
			this.onClick(event, this.buttonAction);
		}
	}
}
