// eslint-disable-next-line no-unused-vars
// import Vue from "vue";
import Vue from "../main";
import ToastificationContent from "../@core/components/toastification/ToastificationContent.vue";

// export async function loadingWrap(vueContext: Vue, functionToExecute: () => Promise<any>, successMessage = "", errorMessage = "Ocorreu um erro. Tente novamente.") {
// 	await vueContext.$vs.loading();
// 	try {
// 		await functionToExecute();
// 		if (successMessage) {
// 			vueContext.$vs.notify({ color: "success", title: "Sucesso", text: successMessage });
// 		}
// 	} catch (error) {
// 		console.error(error);
// 		vueContext.$vs.notify({ color: "danger", title: "Erro", text: errorMessage });
// 	}
// 	await vueContext.$vs.loading.close();
// }

export async function requireConfirmation(
	title = "Deseja prosseguir?",
	text = "Não será possível reverter essa operação",
	acceptText = "Continuar",
) {
	const confirmationResult = await Vue.$bvModal.msgBoxConfirm(text, {
		title,
		size: "sm",
		okVariant: "danger",
		okTitle: acceptText,
		cancelTitle: "Cancelar",
		cancelVariant: "outline-secondary",
		hideHeaderClose: true,
		centered: true,
	});

	if (!confirmationResult) {
		throw new Error("Operation cancelled");
	}
}

export function showAlert(content: { text: string; title: string; variant: string; icon: string }) {
	return Vue.$toast({
		component: ToastificationContent,
		props: {
			title: content.title,
			text: content.text,
			variant: content.variant,
			icon: content.icon,
		},
	});
}

export function showSuccessAlert(text: string, title = "Sucesso") {
	return showAlert({ text, title, variant: "success", icon: "CheckCircleIcon" });
}

export function showErrorAlert(text: string, title = "Erro") {
	return showAlert({ text, title, variant: "danger", icon: "AlertCircleIcon" });
}

export function hasMissingRequiredKeys(headers: any[], formData: any) {
	const missingKeys: string[] = [];
	headers.forEach(
		(header: {
			required: boolean;
			createOnly: boolean;
			key: string;
			keyToCompare: string;
			keyEntity: string;
			relation: string;
			ifKey: string;
			ifCondition: { type: any; condition: any };
		}) => {
			if (header.createOnly) {
				return;
			}
			if (!header.required) {
				return;
			}
			if (header.required && typeof formData[header.key] == "boolean") {
				return;
			}
			if (
				(header.required && !header.keyEntity && !formData[header.key]) ||
				(header.required && header.relation && !formData[header.key][header.relation]) ||
				(header.required &&
					formData[header.key] &&
					formData[header.key] instanceof Array &&
					!formData[header.key].length) ||
				(header.keyToCompare && formData[header.key] !== formData[header.keyToCompare]) ||
				(header.required && formData[header.key] === "R$ 0,00") ||
				(header.keyEntity && !formData[header.keyEntity])
			) {
				missingKeys.push(header.key);
				return;
			}
		},
	);

	return !!missingKeys.length;
}

export function returnFormToDefaultValue(headers: any[], formData: any) {
	if (!formData) {
		return;
	}
	headers.forEach((header: { default: any; key: string; error: string; relation: string }) => {
		header.error = "";
		if (!header.key) {
			return;
		}
		if (header.relation && formData[header.key]) {
			formData[header.key][header.relation] = header.default || "";
		} else {
			formData[header.key] = header.default || "";
		}
	});
}

export function summarizeText(text: string, maxCharacters: number, trimCharactersAt: number) {
	return text.length > maxCharacters ? text.substr(0, trimCharactersAt).trimRight() + "..." : text;
}
