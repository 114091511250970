




































import { Component, Vue, Prop } from "vue-property-decorator";

const BALLOON_ID = "top-level-absolute-balloon";

@Component
export default class GuideBalloon extends Vue {
	@Prop({ default: false }) setAllClasses: boolean;
	@Prop({ default: "" }) balloonStyle: string;
	@Prop({ default: undefined }) text: string;
	@Prop({ default: "" }) icon: string;
	@Prop() featherIcon: { icon: string; size: string; color: string; style: string };
	@Prop({ default: "w-4" }) iconStyle: string;
	@Prop({ default: false }) isStaticBalloon: boolean; //balloon type (static or normal)
	@Prop({ default: () => ({ x: 15, y: 30 }) }) staticOffset: { x: number; y: number };

	get ballonTypeStyle() {
		return !this.isStaticBalloon
			? `w-10/12 right-1/2 translate-x-1/2 transform help-balloon p-1 rounded-lg top-12`
			: "hidden max-w-xs p-0.5 rounded-md";
	}

	mounted() {
		if (this.isStaticBalloon && this.text) {
			const localImage = this.$refs.helpElement as any;
			const localBalloon = this.$refs.helpBalloon as any;
			let topLevelAbsoluteBalloon = document.getElementById(BALLOON_ID);

			//WARN creating only one top-level balloon element that will share the text among the help-icons
			if (!topLevelAbsoluteBalloon) {
				const clonedNode = localBalloon.cloneNode(true);
				clonedNode.id = BALLOON_ID;
				topLevelAbsoluteBalloon = document.body.appendChild(clonedNode);
			}

			localBalloon.remove();
			localImage.addEventListener("mouseover", () => {
				const { top, left } = localImage.getBoundingClientRect();
				const { x, y } = this.staticOffset;
				const { scrollTop: pageScrollTop, scrollLeft: pageScrollLeft } = document.documentElement;
				Object.assign(topLevelAbsoluteBalloon!.style, {
					left: `${left + x + pageScrollLeft}px`,
					top: `${top + y + pageScrollTop}px`,
				});
				topLevelAbsoluteBalloon!.textContent = this.text;
				topLevelAbsoluteBalloon!.classList.remove("hidden");
				topLevelAbsoluteBalloon!.classList.add("block");
			});

			localImage.addEventListener("mouseout", () => {
				topLevelAbsoluteBalloon!.classList.remove("block");
				topLevelAbsoluteBalloon!.classList.add("hidden");
			});
		}
	}
}
