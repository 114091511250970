









import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class InitialsBadge extends Vue {
	@Prop({ default: "" }) entityInitials!: string;

	getInitials(name: string) {
		const names = name.split(" ");
		let initials = names[0].substring(0, 1).toUpperCase();

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	}
}
