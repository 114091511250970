var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"calendar"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"calendar",class:[_vm.absoluteOptions && 'absolute z-50', _vm.isModalLike && 'w-full absolute z-50 h-full top-0 left-0']},[(_vm.isModalLike)?_c('div',{staticClass:"modal-overlay absolute w-full h-full bg-journey-black opacity-10 z-50",on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_c('div',{staticClass:"bg-journey-white",class:[
				_vm.calendarEnclosureStyle,
				_vm.isModalLike &&
					'z-50 top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 py-1 shadow-md rounded-sm' ]},[_c('div',{staticClass:"flex justify-between",class:_vm.isModalLike && 'px-1',staticStyle:{"margin-bottom":"0.8rem"}},[_c('h1',{staticClass:"font-bold text-base text-journey-black m-0 flex items-center"},[_vm._t("header",function(){return [_vm._v(" "+_vm._s((_vm.translatedMonth + ", " + _vm.year))+" ")]},{"timeData":{ translatedMonth: _vm.translatedMonth, year: _vm.year }})],2),_vm._t("default"),_c('div',{staticClass:"flex gap-6 items-center h-6"},[_c('button',{staticClass:"h-full",class:_vm.isPreviousButtonDisabled && 'filter invert pointer-events-none',on:{"click":_vm.goToPreviousCalendarSection}},[_c('feather-icon',{attrs:{"size":"1.5x","icon":"ChevronLeftIcon"}})],1),_c('button',{staticClass:"h-full",class:_vm.isNextButtonDisabled && 'filter invert pointer-events-none',on:{"click":_vm.goToNextCalendarSection}},[_c('feather-icon',{attrs:{"size":"1.5x","icon":"ChevronRightIcon"}})],1)])],2),_c('div',{class:['text-center grid grid-cols-7 gap-y-3', _vm.calendarStyle]},[_vm._l((_vm.weekdays),function(weekdayName,index){return _c('span',{key:index,staticClass:"text-center"},[_vm._v(_vm._s(weekdayName))])}),(_vm.isWeekView)?_vm._l((_vm.months[_vm.currentMonthIndex][_vm.currentWeekIndex]),function(weekday,weekdayIndex){return _c('span',{key:("month-" + _vm.currentMonthIndex + "-week-" + _vm.currentWeekIndex + "-day-" + weekdayIndex),class:{
							highlighted:
								_vm.highlightedDates.has(_vm.buildDateStringFromWeekdayObject(weekday)) && weekday.relatedMonth === 'current',
						}},[_c('button',{class:[
								'font-bold rounded-full w-7 h-7',
								weekday.isDisabled ? 'text-journey-gray pointer-events-none' : 'text-journey-black',
								{
									'bg-gray-200': _vm.isMonthEdgeDay(weekday),
									'bg-journey-emerald-green text-journey-white': weekday.isSelected,
									'border-1 border-journey-emerald-green':
										_vm.buildDateStringFromWeekdayObject(weekday) === _vm.todayIsoDate && weekday.relatedMonth === 'current',
								} ],on:{"click":function($event){return _vm.selectDay(_vm.currentMonthIndex, _vm.currentWeekIndex, weekdayIndex)}}},[_vm._v(" "+_vm._s(weekday.day)+" ")])])}):_vm._e(),(!_vm.isWeekView)?[_vm._l((_vm.months[_vm.currentMonthIndex]),function(week,weekIndex){return _vm._l((week),function(weekday,weekdayIndex){return _c('span',{key:("month-" + _vm.currentMonthIndex + "-week-" + weekIndex + "-day-" + weekdayIndex),class:[
								{
									highlighted:
										_vm.highlightedDates.has(_vm.buildDateStringFromWeekdayObject(weekday)) &&
										weekday.relatedMonth === 'current',
								},
								_vm.canShowAppointments ? 'flex flex-col items-center' : '' ]},[_c('button',{class:[
									'font-bold rounded-full w-7 h-7',
									weekday.isDisabled ? 'text-journey-gray pointer-events-none' : 'text-journey-black',
									{
										'bg-gray-200': _vm.isMonthEdgeDay(weekday),
										'bg-journey-emerald-green text-journey-white': weekday.isSelected,
										'border-1 border-journey-emerald-green':
											_vm.buildDateStringFromWeekdayObject(weekday) === _vm.todayIsoDate &&
											weekday.relatedMonth === 'current',
									} ],on:{"click":function($event){return _vm.selectDay(_vm.currentMonthIndex, weekIndex, weekdayIndex)}}},[_vm._v(" "+_vm._s(weekday.day)+" ")]),(
									_vm.canShowAppointments &&
									_vm.appointmentsPerDay[Number(weekday.day)] > 0 &&
									weekday.relatedMonth === 'current'
								)?_c('span',{staticClass:"text-journey-red text-xs"},[_vm._v(" "+_vm._s(_vm.appointmentsPerDay[Number(weekday.day)])+" ")]):_vm._e()])})})]:_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }