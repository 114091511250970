import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { RoleGuard } from "@/guards/role-guard";
import { UserRole, HomePageByRole } from "@/interfaces/user";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			name: "dashboard",
			component: () => import("@/views/dashboard/Dashboard.vue"),
			meta: {
				pageTitle: "Dashboard",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/usuarios/:id?",
			name: "usuarios",
			component: () => import("@/views/users/Users.vue"),
			meta: {
				pageTitle: "Usuários",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/clientes-corporativos/:id?",
			name: "clientes-corporativos",
			component: () => import("@/views/corporateClients/CorporateClients.vue"),
			meta: {
				pageTitle: "Clientes corporativos",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/empresas/:id?",
			name: "empresas",
			component: () => import("@/views/enterprises/Enterprises.vue"),
			meta: {
				pageTitle: "Empresas",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/clientes",
			name: "clientes",
			component: () => import("@/views/clients/Clients.vue"),
			meta: {
				pageTitle: "Clientes",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/mensagens/:id?",
			name: "mensagens",
			component: () => import("@/views/messageTemplates/MessageTemplates.vue"),
			meta: {
				pageTitle: "Mensagens",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/tags/:id?",
			name: "tags",
			component: () => import("@/views/tag/Tag.vue"),
			meta: {
				pageTitle: "Tags",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/cliente-info/:id?",
			name: "cliente-info",
			component: () => import("@/views/clients/ClientsForm.vue"),
			meta: {
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/solucoes/:id?",
			name: "solucoes",
			component: () => import("@/views/solution/Solutions.vue"),
			meta: {
				pageTitle: "Soluções",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR, UserRole.GUARDIANSHIP] }],
			},
		},
		{
			path: "/campanhas",
			name: "campanhas",
			component: () => import("@/views/campaign/Campaign.vue"),
			meta: {
				pageTitle: "Campanhas",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/planos/:id?",
			name: "planos",
			component: () => import("@/views/insurerPlans/InsurerPlans.vue"),
			meta: {
				pageTitle: "Planos",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/servicos/:id?",
			name: "servicos",
			component: () => import("@/views/servicesPlan/ServicesPlan.vue"),
			meta: {
				pageTitle: "Serviços",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/necessidades/:id?",
			name: "necessidades",
			component: () => import("@/views/needs/Needs.vue"),
			meta: {
				pageTitle: "Necessidades",
				guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/promotores/agendamentos/:clientId",
			name: "portalPromotoresAgendamentos",
			component: () => import("@/views/generalAgenda/SchedulesAgenda.vue"),
			meta: {
				kind: "withoutSidebarLayout",
				pageTitle: "Agendamentos",
				guards: [{ run: RoleGuard, args: [UserRole.HEALTH_PROMOTER, UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/promotores",
			name: "portalPromotores",
			component: () => import("@/views/promotersPortal/ClientSearch/ClientSearch.vue"),
			meta: {
				kind: "withoutSidebarLayout",
				pageTitle: "Portal de promotores",
				guards: [{ run: RoleGuard, args: [UserRole.HEALTH_PROMOTER, UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/promotores/:id?",
			name: "perfilCliente",
			component: () => import("@/views/promotersPortal/CustomerProfile.vue"),
			meta: {
				kind: "withoutSidebarLayout",
				pageTitle: "Portal de promotores",
				guards: [{ run: RoleGuard, args: [UserRole.HEALTH_PROMOTER, UserRole.ADMINISTRATOR] }],
			},
		},
		{
			path: "/perfil/:id?",
			name: "Perfil",
			component: () => import("@/views/users/UpdateProfile.vue"),
			meta: {
				kind: "withoutSidebarLayout",
				pageTitle: "Perfil",
				guards: [{ run: RoleGuard, args: [UserRole.HEALTH_PROMOTER, UserRole.ADMINISTRATOR, UserRole.GUARDIANSHIP] }],
			},
		},
		{
			path: "/chat",
			name: "Chat",
			component: () => import("@/views/chat/Chat.vue"),
			meta: {
				kind: "withoutSidebarLayout",
				pageTitle: "Chat",
				guards: [{ run: RoleGuard, args: [UserRole.HEALTH_PROMOTER] }],
			},
		},
		{
			path: "/",
			name: "Agenda",
			component: () => import("@/views/generalAgenda/AgendaIndex.vue"),
			children: [
				{
					path: "agendamentos",
					name: "agendamentos",
					guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
					component: () => import("@/views/generalAgenda/SchedulesAgenda.vue"),
				},
				{
					path: "disponibilidades",
					name: "disponibilidades",
					guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
					component: () => import("@/views/generalAgenda/AvailabilitiesAgenda.vue"),
				},
			],
		},
		{
			path: "/",
			name: "Acompanhamento",
			component: () => import("@/views/reports/ReportIndex.vue"),
			children: [
				{
					path: "relatorio-agendamentos/:id?",
					name: "relatorio-agendamentos",
					guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
					component: () => import("@/views/reports/SolutionAppointment.vue"),
				},
				{
					path: "relatorio-conversas/:id?",
					name: "relatorio-conversas",
					guards: [{ run: RoleGuard, args: [UserRole.ADMINISTRATOR] }],
					component: () => import("@/views/reports/ChatChannel.vue"),
				},
			],
		},
		{
			path: "/login",
			name: "login",
			component: () => import("@/views/Login.vue"),
			meta: {
				layout: "full",
				isPublic: true,
				redirectIfLoggedIn: true,
			},
		},
		{
			path: "/error-404",
			name: "error-404",
			component: () => import("@/views/error/Error404.vue"),
			meta: {
				isPublic: true,
				layout: "full",
			},
		},
		{
			path: "*",
			redirect: "error-404",
		},
	],
});

router.beforeEach(async (to, _, next) => {
	const isLoggedIn = store.getters["auth/isLoggedIn"];
	const authData = store.state.auth;

	if (isLoggedIn && to.meta.redirectIfLoggedIn) {
		return next({ path: HomePageByRole[authData.userData.role] });
	}

	if (!isLoggedIn && !to.meta.isPublic) {
		return next({ name: "login" });
	}

	if (to.meta.guards?.length) {
		const resolvedGuards = await Promise.all(to.meta.guards.map(guard => guard.run(guard.args)));
		// executa o primeiro guard invalido
		const guard = resolvedGuards.find(guard => guard.valid === false);

		if (guard) {
			return next({ path: guard.redirectOnFail });
		}
	}

	return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;
