





































import { Component, Prop, Vue } from "vue-property-decorator";
import { getFieldContent } from "@/helpers/utils";
import InitialsBadge from "./InitialsBadge.vue";
import GuideBalloon from "./GuideBalloon.vue";

export type tagVariant = "primary" | "secondary" | "tertiary";

@Component({
	components: {
		"initials-badge": InitialsBadge,
		GuideBalloon,
	},
})
export default class CustomUserCard extends Vue {
	@Prop({ default: () => [] }) cardData!: any;
	@Prop({ default: () => [] }) cardHeaders!: any;
	@Prop({ default: "" }) customClass!: string;
	@Prop({ default: true }) shouldShowIsActiveIcon: boolean;
	@Prop({ default: "primary" }) variant!: tagVariant;
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event, buttonAction?: string) => void;

	getFieldContent = getFieldContent;
	primaryStyle = "text-journey-black font-bold";
	secondaryStyle = "text-journey-gray font-bold text-sm";

	baseCss =
		"flex flex-row pl-2.5 pr-2 py-2.5 shadow-md bg-journey-white justify-start gap-x-4 items-center cursor-pointer rounded-r-md";
	primaryCss = "border-l-4 border-journey-yellow";
	secondaryCss = "border-l-4 border-journey-orange";
	tertiaryCss = "border-l-4 border-journey-green";

	get cardStyle() {
		let baseStyles = [this.baseCss];
		switch (this.variant) {
			case "primary":
				baseStyles.push(this.primaryCss);
				break;
			case "secondary":
				baseStyles.push(this.secondaryCss);
				break;
			case "tertiary":
				baseStyles.push(this.tertiaryCss);
				break;
		}
		return baseStyles;
	}

	handleClick(event: Event) {
		event.preventDefault();
		if (this.onClick && typeof this.onClick === "function") {
			this.onClick(event, this.cardData);
		}
	}
}
