
import { showSuccessAlert } from "@/helpers";
import { IAddress } from "@/interfaces/address";
import { ISolution } from "@/interfaces/solution";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseSolutionMixin extends Vue {
	@Prop() solution!: ISolution;

	copySolution(additionalData: string[] | PointerEvent) {
		const { address } = this.solution;
		const text: string[] = [];
		const isTelemedicineModality = Array.isArray(additionalData)
			? additionalData.find(data => data === "Teleconsulta")
			: false;
		const solutionCategoryName = this.solution.category.name;
		const solutionProperties: (keyof ISolution)[] = ["name", "examOrDoctorName", "description", "phoneNumber"];
		solutionProperties.forEach(key => {
			const value = this.solution[<keyof ISolution>key];
			if (
				value &&
				value !== "Orientação" &&
				!(isTelemedicineModality && key === "phoneNumber") &&
				!(solutionCategoryName === "Orientações" && key === "name")
			) {
				text.push(<string>value);
			}
		});

		if (address && !isTelemedicineModality) {
			const formattedAddress = this.formatAddress(address);
			if (formattedAddress.length) {
				text.push(formattedAddress);
			}
		}

		if (Array.isArray(additionalData)) {
			text.push(...additionalData.filter((item: string) => item));
		}

		navigator.clipboard.writeText(text.join(" | "));
		showSuccessAlert("", "Solução copiada.");
	}

	formatAddress(address: IAddress) {
		const { street, number, neighborhood, complement, state, city, postCode } = address;

		return `${street ? street + ", " : ""}${number ? number + " - " : ""}${neighborhood ?? ""}${
			complement ? " - " + complement : ""
		}${city ? ", " + city : ""}${state ? " - " + state + " , " : ""}${postCode ?? ""}`;
	}
}
