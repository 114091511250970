





















































import { Component, Prop, Vue } from "vue-property-decorator";
import GuideBalloon from "./GuideBalloon.vue";

@Component({
	components: {
		GuideBalloon,
	},
})
export default class CustomCard extends Vue {
	@Prop({ default: () => [] }) cardData!: any;
	@Prop({ default: () => [] }) cardHeaders!: any;
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event) => void;

	primaryStyle = "text-journey-black font-bold";
	secondaryStyle = "text-journey-gray font-bold text-sm";

	handleClick(event: Event) {
		event.preventDefault();
		if (this.onClick && typeof this.onClick === "function") {
			this.onClick(event);
		}
	}
}
