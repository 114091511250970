import { BaseEntity } from "../entities/base_entity";
import { Gender } from "./gender.enum";

export enum UserRole {
	HEALTH_PROMOTER = "HEALTH_PROMOTER",
	GUARDIANSHIP = "GUARDIANSHIP",
	ADMINISTRATOR = "ADMINISTRATOR",
}

export enum UserStatus {
	UNCONFIRMED_EMAIL = "UNCONFIRMED_EMAIL",
	APPROVAL_PENDING = "APPROVAL_PENDING",
	ACTIVE = "ACTIVE",
}

export enum ContractKind {
	PF = "PF",
	PJ = "PJ",
	CLT = "CLT",
	SERVICE_PROVISION = "SERVICE_PROVISION",
	OTHER = "OTHER",
}

export enum WorkloadKind {
	WEEKLY = "WEEKLY",
	MONTHLY = "MONTHLY",
}

export enum UserWorkStatus {
	AWAY = "AWAY",
	ACTIVE = "ACTIVE",
}

export interface IUser extends BaseEntity<IUser> {
	role: UserRole;
	status: UserStatus;
	workStatus: UserWorkStatus;
	email: string;
	password: string;
	name: string;
	lastName: string;
	birthDate: Date;
	phoneNumber: string;
	gender: Gender;
	cpf: string;
	rg: string;
	about: string;
	profilePictureUrl?: string;
	occupation: OccupationKind;
	councilNumber: string;
	jobFunction: string;
	jobFunctionDescription: string;
	workload: WorkloadKind;
	contractKind: ContractKind;
	startDate: Date;
	observation: string;
	addressStreet: string;
	addressNumber: string | "N/A";
	addressComplement: string;
	addressNeighborhood: string;
	addressCity: string;
	addressState: string;
	addressPostCode: string;
	userCorporateClients: any;
}

export enum OccupationKind {
	PSYCHOLOGY = "PSYCHOLOGY",
	NURSE = "NURSE",
	NUTRITIONIST = "NUTRITIONIST",
	GERONTOLOGIST = "GERONTOLOGIST",
	DOCTOR = "DOCTOR",
	SOCIAL_ASSISTANT = "SOCIAL_ASSISTANT",
	INTERN = "INTERN",
	PHYSIOTHERAPIST = "PHYSIOTHERAPIST",
	PHYSICAL_EDUCATOR = "PHYSICAL_EDUCATOR",
	PHARMACEUTICAL = "PHARMACEUTICAL",
	SPEECH_THERAPIST = "SPEECH_THERAPIST",
	ADMINISTRATION = "ADMINISTRATION",
	OTHERS = "OTHERS",
}

export const occupationTranslated: Record<OccupationKind, string> = {
	[OccupationKind.ADMINISTRATION]: "Administração",
	[OccupationKind.PSYCHOLOGY]: "PSICÓLOGA (O)",
	[OccupationKind.NURSE]: "ENFERMEIRA (O)",
	[OccupationKind.NUTRITIONIST]: "NUTRICIONISTA",
	[OccupationKind.GERONTOLOGIST]: "GERONTÓLOGA (O)",
	[OccupationKind.DOCTOR]: "MÉDICA (O)",
	[OccupationKind.SOCIAL_ASSISTANT]: "ASSISTENTE SOCIAL",
	[OccupationKind.INTERN]: "ESTAGIÁRIA (O)",
	[OccupationKind.PHYSIOTHERAPIST]: "FISIOTERAPEUTA",
	[OccupationKind.PHYSICAL_EDUCATOR]: "EDUCADOR FÍSICO",
	[OccupationKind.PHARMACEUTICAL]: "FARMACÊUTICA (O)",
	[OccupationKind.SPEECH_THERAPIST]: "FONAUDIÓLOGA (O)",
	[OccupationKind.ADMINISTRATION]: "ADMINISTRAÇÃO",
	[OccupationKind.OTHERS]: "OUTRAS",
};

export function translateOccupation(status?: OccupationKind): string {
	if (!status) return "";
	return occupationTranslated[status];
}

export const userRoleTranslated: Record<UserRole, string> = {
	[UserRole.ADMINISTRATOR]: "Administrador",
	[UserRole.HEALTH_PROMOTER]: "Promotor(a) de saúde",
	[UserRole.GUARDIANSHIP]: "Curadoria",
};

export function translateUserRole(status?: UserRole): string {
	if (!status) return "";
	return userRoleTranslated[status];
}

export const HomePageByRole: Record<UserRole, string> = {
	[UserRole.ADMINISTRATOR]: "/",
	[UserRole.GUARDIANSHIP]: "/solucoes",
	[UserRole.HEALTH_PROMOTER]: "/promotores",
};

export const genderTranslated: Record<Gender, string> = {
	[Gender.FEMALE]: "Feminino",
	[Gender.MALE]: "Masculino",
	[Gender.OTHER]: "Outro",
};

export function translateGender(status?: Gender): string {
	if (!status) return "";
	return genderTranslated[status];
}

export const workloadTranslated: Record<WorkloadKind, string> = {
	[WorkloadKind.MONTHLY]: "Mensal",
	[WorkloadKind.WEEKLY]: "Semanal",
};

export function translateWorkloadKind(status?: WorkloadKind): string {
	if (!status) return "";
	return workloadTranslated[status];
}

export const contractKindTranslated: Record<ContractKind, string> = {
	[ContractKind.CLT]: "CLT",
	[ContractKind.PJ]: "Pessoa Júridica",
	[ContractKind.PF]: "Pessoa Física",
	[ContractKind.SERVICE_PROVISION]: "Prestação de serviços",
	[ContractKind.OTHER]: "Outro",
};

export function translateContractKind(status?: ContractKind): string {
	if (!status) return "";
	return contractKindTranslated[status];
}
