


















































































































import { IFullCalendarEvent } from "@/interfaces/full_calendar_event";
import { Component, Vue, Prop } from "vue-property-decorator";
import GuideBalloon from "@/layouts/components/GuideBalloon.vue";
import SolutionAvailabilitySelectionModal from "@/layouts/components/SolutionAvailabilitySelectionModal.vue";
import store from "@/store";
import { format, isBefore } from "date-fns";
import { ptBR } from "date-fns/locale";
import { requireConfirmation, showSuccessAlert } from "@/helpers";
import { crudDelete } from "@/api/_request";

@Component({
	components: {
		GuideBalloon,
		SolutionAvailabilitySelectionModal,
	},
})
export default class AgendaEventBalloon extends Vue {
	@Prop() event: IFullCalendarEvent;

	isAppointmentEditModalOpen = false;
	disabledColor = "#cecece";

	get initialAvailability() {
		const { start: startDateTime, end: endDateTime } = this.event;
		return {
			id: this.props.availabilityId,
			startDateTime,
			endDateTime,
			modality: this.props.availabilityModality,
		};
	}

	get startDay() {
		return format(new Date(this.event.start), "EEEE', ' LLLL dd", { locale: ptBR });
	}
	get startHour() {
		return format(new Date(this.event.start), "HH:mm");
	}
	get endHour() {
		return format(new Date(this.event.end), "HH:mm");
	}
	get props() {
		return this.event.extendedProps;
	}
	get promoter() {
		return this.props?.promoter ?? {};
	}
	get client() {
		return this.props?.client ?? {};
	}
	get insurerPlan() {
		return this.props?.insurerPlan ?? {};
	}
	get corporateClient() {
		return this.props?.corporateClient ?? {};
	}
	get isSchedule() {
		return this.props.isScheduleView;
	}
	get isTakenAndCompletedAtAvailabilityView() {
		return !this.isSchedule && this.props.isTaken && this.props.appointmentStatus === "COMPLETED";
	}

	isBeforeNow(date: string) {
		return isBefore(new Date(date), new Date());
	}

	closeBalloon() {
		store.commit("appConfig/UPDATE_AGENDA_BALLOON", { isOpened: false, isHovered: false });
	}

	async removeAppointmentOrAvailability(id: string) {
		await requireConfirmation("Confirmar exclusão?", "Não será possível reverter esta operação.", "Excluir");
		await crudDelete(
			`${
				this.isSchedule || this.props.appointmentStatus === "PRE_SELECTED"
					? `app/solutionAppointment`
					: `admin/availability`
			}`,
			this.isSchedule || this.props.appointmentStatus === "PRE_SELECTED" ? this.props.appointmentId : id,
		);

		const event = store.state.appConfig.layout.calendarApi.getEventById(id);
		this.isSchedule || this.props.appointmentStatus === "PRE_SELECTED" ? this.updateEvent(event) : event.remove();
		showSuccessAlert("Registro excluído.");

		this.closeBalloon();
	}

	updateEvent(event: any) {
		const { backgroundColor, borderColor, extendedProps, id, overlap, startEditable, title, start, end } = event;
		const {
			availabilityId,
			appointmentId,
			solutionId,
			categoryName,
			corporateClient,
			insurerPlan,
			promoter,
			isScheduleView,
		} = extendedProps;
		const mainProps = { id, backgroundColor, borderColor, overlap, startEditable, title, start, end };
		const updatedEvent = {
			...mainProps,
			extendedProps: {
				availabilityId,
				appointmentId,
				categoryName,
				client: null,
				corporateClient,
				insurerPlan,
				promoter,
				solutionId,
				isScheduleView,
				isTaken: 0,
			},
		};

		event.remove();
		store.state.appConfig.layout.calendarApi.addEvent(updatedEvent);
	}

	onAppointmentSave() {
		this.isAppointmentEditModalOpen = false;
		this.closeBalloon();
	}
}
