
















































































































































































































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { format } from "date-fns";
import { IAvailability } from "@/interfaces/availability";
import { BSpinner } from "bootstrap-vue";
import { IUser } from "@/interfaces/user";
import BaseModal from "./BaseModal.vue";
import BaseSolutionMixin from "../mixins/base_solution.vue";
import Button from "./Button.vue";
import UserCard from "./UserCard.vue";
import Calendar from "./Calendar.vue";
import Checkbox from "./Checkbox.vue";
import GuideBalloon from "./GuideBalloon.vue";
import VueSelect from "vue-select";
import { findNextWorkDayAfterDays, isBetween, readableDay, toIsoDate } from "@/helpers/calendar";
import { crudCreate, crudGet, crudPatch } from "@/api/_request";
import { showErrorAlert, showSuccessAlert } from "@/helpers";
import store from "@/store";
import { AppointmentModality, IAppointment } from "@/interfaces/appointment";
import { DeepPartial } from "@/entities/base_entity";
import { SolutionSelection } from "@/views/promotersPortal/CustomerProfile.vue";

type SectionId = string;
type IsoDate = string;
type DatesLiteral = Record<IsoDate, IAvailability[]>;
type Modality = string;

@Component({
	components: {
		VueSelect,
		BaseModal,
		BSpinner,
		Calendar,
		UserCard,
		Button,
		GuideBalloon,
		Checkbox,
	},
})
export default class SolutionAvailabilitySelectionModal extends Mixins(BaseSolutionMixin) {
	@Prop({ default: false }) isOpen: boolean;
	@Prop({ default: () => null }) onModalClosed: () => void;
	@Prop() selectedModalityInitialValue: any;
	@Prop() visualData: string;
	@Prop() user: IUser;
	@Prop() header: any;

	lastSelectedSectionData: any = null;
	sectionIdsToForceReUpdate = new Set<string>();
	datesLiteral: DatesLiteral | null = null;
	fetchedCalendarSections: Record<Modality, Record<SectionId, DatesLiteral>> = {
		[AppointmentModality.IN_PERSON]: {},
		[AppointmentModality.TELEMEDICINE]: {},
	};

	selectedAvailability: IAvailability | null = null;
	hasAvailability: boolean = false;

	selectedDate: string = "";
	modalities = [
		{ icon: "presential", isSelected: true, name: "Presencial", value: AppointmentModality.IN_PERSON },
		{ icon: "telemedicine", isSelected: false, name: "Teleconsulta", value: AppointmentModality.TELEMEDICINE },
	];
	previousSelectedModalityIndex: number = 0;
	selectedModality: AppointmentModality = this.modalities[this.previousSelectedModalityIndex].value;

	isLoading = false;
	isConfirmed = false;
	buttonTextInitialValue = "Salvar Consulta";
	buttonText = this.buttonTextInitialValue;

	//edit view on agenda
	@Prop({ default: false }) isSimpleEditViewInitialValue!: boolean;
	@Prop({ default: "" }) initialReturnDate!: string;
	@Prop() preSelectedAvailabilityInitialValue!: IAvailability;
	@Prop({ default: "" }) appointmentIdInitialValue!: string;
	preSelectedAvailability: IAvailability | null = null;
	appointmentId = "";
	selectedReturnDate = "";
	hasReturnDate = false;
	isReturnDateCalendarOpen = false;
	isSimpleEditView = false;

	async searchSomeAvailability() {
		try {
			return crudGet("app", "availability/hasAvailability", {
				solution: { id: this.solution.id },
			});
		} catch (e) {
			showErrorAlert("Ocorreu um erro. Tente novamente.");
		}
	}

	@Watch("isOpen")
	async handleSimpleEditViewModal() {
		if (this.isOpen) {
			if (this.selectedModalityInitialValue !== this.selectedModality && this.selectedModalityInitialValue) {
				const index = this.modalities.findIndex(({ value }) => this.selectedModalityInitialValue === value);
				this.selectModality(index);
			}
			if (this.initialReturnDate) {
				this.hasReturnDate = !!this.initialReturnDate;
			}
			const areAllIdsEquivalent =
				new Set([
					this.selectedAvailability?.id,
					this.preSelectedAvailability?.id,
					this.preSelectedAvailabilityInitialValue?.id,
				]).size === 1;

			if (this.preSelectedAvailabilityInitialValue && !areAllIdsEquivalent) {
				this.preSelectedAvailability = this.preSelectedAvailabilityInitialValue;
				this.selectedDate = this.preSelectedAvailability.startDateTime as string;
				const { currentSectionData } = this.$refs.availabilityCalendar as Calendar;
				this.sectionIdsToForceReUpdate.add(currentSectionData.sectionId);
			}
			if (this.initialReturnDate) {
				this.selectedReturnDate = this.initialReturnDate ?? findNextWorkDayAfterDays(toIsoDate(new Date()), 0);
			}
			if (this.isSimpleEditViewInitialValue) {
				this.isSimpleEditView = this.isSimpleEditViewInitialValue;
			}
			if (this.appointmentIdInitialValue) {
				this.appointmentId = this.appointmentIdInitialValue;
			}

			if (!this.datesLiteral || this.isSimpleEditView) {
				await this.searchAvailabilities();
			}

			this.hasAvailability = await this.searchSomeAvailability();
		} else {
			this.isReturnDateCalendarOpen = false;
		}
	}

	selectHour(availability: IAvailability, index: number) {
		//  || !this.isAvailabilityPreScheduledByCurrentUser(availability)
		if (!availability?.appointment) {
			this.selectAvailability(availability, index);
		}
	}

	selectAvailability(availability: IAvailability, index: number) {
		this.selectedAvailability = availability;
		this.selectedAvailability.index = index;
	}

	isAvailabilityPreScheduledByCurrentUser({ appointment }: IAvailability) {
		return appointment && store.state.auth.activeUser.id === appointment?.user?.id;
	}

	getHourGuideBalloonText(availability: IAvailability) {
		//  && !this.isAvailabilityPreScheduledByCurrentUser(availability)
		if (availability.appointment) {
			const { name, lastName } = availability.appointment!.user;
			return `Pré-agendado por ${name}${lastName ? " " + lastName : ""}`;
		}
		return null;
	}

	get isAtSchedulesPage() {
		return this.$route.path.includes("agendamentos");
	}

	get todayIsoDate() {
		return toIsoDate(new Date());
	}

	get readableHour() {
		if (!this.selectedAvailability) {
			return;
		}
		return `Horário: ${format(new Date(this.selectedAvailability.startDateTime), "HH:mm")}`;
	}

	readableDay(availability: IAvailability, solutionCategoryName: string) {
		if (!availability) {
			return "";
		}
		return readableDay(format(new Date(availability.startDateTime), "yyyy-MM-dd"));
	}

	get currentDates() {
		return Object.keys(this.datesLiteral ?? {});
	}

	get hasAvailabilities() {
		return !!this.currentDates.length;
	}

	readableSelectedTime(availability: IAvailability, shouldIncludeEndDateTime = true) {
		if (!availability) {
			return;
		}
		const { startDateTime: start, endDateTime: end } = availability;
		return `${format(new Date(start), "HH:mm")}${
			shouldIncludeEndDateTime ? ` às ${format(new Date(end), "HH:mm")}` : ""
		}`;
	}

	get readableReturnDate() {
		return readableDay(this.selectedReturnDate);
	}

	get currentAvailabilities() {
		if (!this.selectedDate) {
			return [];
		}
		return this.datesLiteral?.[format(new Date(this.selectedDate), "yyyy-MM-dd")] ?? [];
	}

	getHour(date: Date | string) {
		return format(new Date(date), "HH:mm");
	}

	async selectModality(index: number) {
		const isSameSelection = this.previousSelectedModalityIndex === index;

		if (!isSameSelection && this.previousSelectedModalityIndex !== null) {
			this.modalities[this.previousSelectedModalityIndex].isSelected = false;
		}
		if (!isSameSelection) {
			this.modalities[index].isSelected = true;
			this.previousSelectedModalityIndex = index;
			this.selectedModality = this.modalities[index].value;
			await this.searchAvailabilities();
		}
	}

	onBackButton() {
		if (this.isConfirmed) {
			this.isConfirmed = false;
			this.buttonText = this.buttonTextInitialValue;
		} else {
			this.onModalClosed();
		}
	}

	async searchAvailabilities() {
		try {
			this.isLoading = true;
			const { currentSectionData } = this.$refs.availabilityCalendar as Calendar;
			const { range, isWholeSectionDisabled, sectionId } = currentSectionData;
			if (isWholeSectionDisabled) {
				this.datesLiteral = null;
				return;
			}
			const currentModalityDatesSection = this.fetchedCalendarSections[this.selectedModality as string];
			if (this.sectionIdsToForceReUpdate.has(sectionId)) {
				delete currentModalityDatesSection[sectionId];
				this.sectionIdsToForceReUpdate.delete(sectionId);
			}

			if (currentModalityDatesSection[sectionId]) {
				this.datesLiteral = currentModalityDatesSection[sectionId];
				return;
			}

			const datesLiteral: DatesLiteral = await crudGet("app", "availability", {
				solution: { id: this.solution.id },
				isTaken: false,
				shouldFormatByDateLiteral: true,
				isPreSelected: true,
				modality: this.selectedModality,
				range,
			});

			if (this.preSelectedAvailability) {
				const dateKey = format(new Date(this.preSelectedAvailability.startDateTime), "yyyy-MM-dd");
				const incomingAvailabilitiesAtSameDate = datesLiteral?.[dateKey] ?? [];
				const index = incomingAvailabilitiesAtSameDate.findIndex(({ id }) => id === this.preSelectedAvailability?.id);
				const preSelectedIncomingAvailability = incomingAvailabilitiesAtSameDate[index];
				if (preSelectedIncomingAvailability) {
					//preSelectedIncomingAvailability == this.preSelectedAvailability
					this.selectAvailability(preSelectedIncomingAvailability, index);
				} else if (
					(this.preSelectedAvailability.modality as string).includes(this.selectedModality as string) &&
					isBetween(dateKey, range)
				) {
					datesLiteral[dateKey] = [this.preSelectedAvailability, ...incomingAvailabilitiesAtSameDate];
					this.selectAvailability(this.preSelectedAvailability, 0);
				}
			}

			currentModalityDatesSection[sectionId] = datesLiteral;
			this.datesLiteral = datesLiteral;
		} catch (e) {
			showErrorAlert("Ocorreu um erro. Tente novamente.");
		} finally {
			this.isLoading = false;
		}
	}

	async createOrUpdateAppointment() {
		if (!this.isConfirmed && !this.isSimpleEditView) {
			const { selectedSectionData } = this.$refs.availabilityCalendar as Calendar;
			this.lastSelectedSectionData = selectedSectionData;

			this.isConfirmed = true;
			this.buttonText = "Confirmar Consulta";
		} else if (!this.isLoading) {
			if (!this.isSimpleEditView) {
				try {
					this.isLoading = true;
					if (this.appointmentDto.scheduling && this.selectedAvailability?.id) {
						this.preSelectedAvailability = this.selectedAvailability!;
						this.sectionIdsToForceReUpdate.add(this.lastSelectedSectionData.sectionId);
					}

					const savedAppointment: IAppointment = await crudCreate("app/solutionAppointment", this.appointmentDto);
					this.$emit("handle-solution-selection-by-confirming-modal", {
						isSelected: true,
						solution: this.solution,
						shouldPlaceToPreSelectedSection: true,
					} as SolutionSelection);
					this.onModalClosed?.();
					this.appointmentId = savedAppointment.id!;
					this.solution.appointment = savedAppointment;
					this.isSimpleEditView = true;
					this.isConfirmed = false;

					showSuccessAlert("Apontamendo pré-selecionado com sucesso.");
				} catch (error) {
					showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
				} finally {
					this.isLoading = false;
				}
			} else {
				try {
					this.isLoading = true;

					const { client, solution, need, ...clearedAppointment } = this.appointmentDto;
					await crudPatch("app/solutionAppointment", this.appointmentId, clearedAppointment);

					if (this.isAtSchedulesPage) {
						const { calendarApi } = store.state.appConfig.layout;
						const event = calendarApi.getEventById(this.appointmentId);
						const { backgroundColor, borderColor, extendedProps, id, overlap, startEditable, title, start, end } =
							event;
						const {
							availabilityId,
							appointmentId,
							solutionId,
							categoryName,
							client,
							corporateClient,
							insurerPlan,
							promoter,
							isScheduleView,
						} = extendedProps;
						event.remove();

						const mainProps = { id, backgroundColor, borderColor, overlap, startEditable, title, start, end };
						const updatedEvent = {
							...mainProps,
							extendedProps: {
								availabilityId,
								appointmentId,
								categoryName,
								client,
								corporateClient,
								insurerPlan,
								promoter,
								solutionId,
								isScheduleView,
								modality: this.appointmentDto.modality,
								returnDate: this.appointmentDto.returnDate,
							},
						};

						if (this.selectedAvailability) {
							const { startDateTime: start, endDateTime: end, id, modality } = this.selectedAvailability;
							Object.assign(updatedEvent, { start, end });
							Object.assign(updatedEvent.extendedProps, { availabilityId: id, availabilityModality: modality });

							const { selectedSectionData } = this.$refs.availabilityCalendar as Calendar;
							this.sectionIdsToForceReUpdate.add(selectedSectionData?.sectionId!);
						}
						calendarApi.addEvent(updatedEvent);
					}

					if (clearedAppointment.scheduling?.availability) {
						const { id, startDateTime, endDateTime } = this.selectedAvailability!;
						Object.assign(clearedAppointment.scheduling?.availability, { id, startDateTime, endDateTime });
					}

					this.$emit("on-appointment-update", clearedAppointment);
					showSuccessAlert("Apontamendo atualizado.");
				} catch (error) {
					showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
				} finally {
					this.isLoading = false;
				}
			}
		}
	}

	get appointmentDto() {
		const { id, category, relatedNeed, conversationContext } = this.solution;
		const isSchedulingActive = category.isAvailabilityInputActive;

		const appointment: DeepPartial<IAppointment> = {
			solution: { id },
			returnDate: this.hasReturnDate ? this.selectedReturnDate : undefined,
			...(this.user?.id ? { client: { id: this.user.id } } : {}),
			...(relatedNeed?.id && { need: { id: relatedNeed.id }, reportedNeedName: relatedNeed.name }),
			...(conversationContext ? { conversationContext } : {}),
			...(this.selectedModality ? { modality: this.selectedModality } : {}),
		};

		if (isSchedulingActive && this.selectedAvailability?.id) {
			Object.assign(appointment, { scheduling: { availability: { id: this.selectedAvailability.id } } });
		}

		return appointment;
	}
}
