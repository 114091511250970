import { BaseEntity } from "../entities/base_entity";
import { IAddress } from "./address";

export interface ICorporateClient extends BaseEntity<ICorporateClient> {
	name: string;
	cnpj: string;
	address: IAddress;
	personInChargeName: string;
	personInChargePhoneNumber: string;
	phoneNumber: string;
	contractSummary: string;
	attachmentUrl: string;
}

export enum ClientKind {
	HEALTH_INSURANCE = "HEALTH_INSURANCE",
	SELF_MANAGEMENT = "SELF_MANAGEMENT",
	MEDICAL_COOPERATIVE = "MEDICAL_COOPERATIVE",
	GROUP_MEDICINE = "GROUP_MEDICINE",
	PHILANTHROPY = "PHILANTHROPY",
	COMPANY = "COMPANY",
	OTHERS = "OTHERS",
}

export enum InsurerPlanRelationKind {
	DEPENDENT = "DEPENDENT",
	HOLDER = "HOLDER",
}

export const clientKindTranslated: Record<ClientKind, string> = {
	[ClientKind.HEALTH_INSURANCE]: "Seguradora de Saúde",
	[ClientKind.SELF_MANAGEMENT]: "Autogestão",
	[ClientKind.MEDICAL_COOPERATIVE]: "Cooperativa médica",
	[ClientKind.GROUP_MEDICINE]: "Medicina em grupo",
	[ClientKind.PHILANTHROPY]: "Filantropia",
	[ClientKind.COMPANY]: "Empresa",
	[ClientKind.OTHERS]: "Outras",
};

export function translateClientKind(status?: ClientKind): string {
	if (!status) return "";
	return clientKindTranslated[status];
}

export const insurerPlanRelationKindTranslated: Record<InsurerPlanRelationKind, string> = {
	[InsurerPlanRelationKind.DEPENDENT]: "Dependente",
	[InsurerPlanRelationKind.HOLDER]: "Titular",
};

export function translateInsurerPlanRelationKind(status?: InsurerPlanRelationKind): string {
	if (!status) return "";
	return insurerPlanRelationKindTranslated[status];
}
