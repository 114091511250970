import { IGuardResponse } from "@/interfaces/guard_response";
import { IUser, HomePageByRole } from "@/interfaces/user";
import store from "@/store";

export async function RoleGuard(roles: string[]): Promise<IGuardResponse> {
	const user: IUser = store.state.auth.activeUser;
	return {
		// evita verificação de [undefined].includes(undefined)
		valid: roles.includes(user.role || null),
		redirectOnFail: user ? HomePageByRole[user.role] : "login",
	};
}
