import { post, patch } from "./_request";

export async function login({
	email,
	password,
}: {
	email: string;
	password: string;
}): Promise<{ user: any; token: string }> {
	return post("auth", "login", { email, password });
}

export async function logout(): Promise<void> {
	return post("auth", "logout", {});
}

export async function requestPasswordReset({ email }: { email: string }): Promise<boolean> {
	return post("auth", "requestPasswordReset", { email });
}

export async function resetPassword({
	passwordResetCode,
	password,
}: {
	passwordResetCode: string;
	password: string;
}): Promise<boolean> {
	return patch("auth", "resetPassword", { passwordResetCode, password });
}
