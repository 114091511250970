import { translateGender, translateUserRole } from "@/interfaces/user";
import { translateClientKind } from "@/interfaces/corporate_client";
import { addHours, differenceInYears, format, parseISO } from "date-fns";
import { IAppointment } from "@/interfaces/appointment";
import { Gender } from "@/interfaces/gender.enum";

export function conditionsAreApproved(
	header: {
		ifKey: string;
		ifCondition: { type: "equal" | "different" | "includes" | "exists" | "inexistent"; condition: any };
		ifConditions?: any[];
	},
	formData: any,
) {
	let response = true;
	if (!header.ifKey && !header.ifConditions) {
		return response;
	}
	if (!header.ifConditions) {
		header.ifConditions = [{ ifKey: header.ifKey, ifCondition: header.ifCondition }];
	}
	header.ifConditions.forEach(headerCondition => {
		if (!response) {
			return;
		}
		switch (headerCondition.ifCondition.type) {
			case "equal":
				response = headerCondition.ifCondition.condition === formData[headerCondition.ifKey];
				break;
			case "different":
				response = headerCondition.ifCondition.condition !== formData[headerCondition.ifKey];
				break;
			case "includes":
				response = formData[headerCondition.ifKey].includes(headerCondition.ifCondition.condition);
				break;
			case "exists":
				response = !!formData[headerCondition.ifKey];
				break;
			case "inexistent":
				response = !formData[headerCondition.ifKey];
				break;
			default:
				response = false;
		}
	});

	return response;
}

export function formatDayMonthYear(isoDate?: Date | string) {
	if (!isoDate) return;

	//NOTATION: Tratar casos que foram salvos no banco com o horário 00:00:00, na conversão vai transformar para o dia anterior
	let currentIsoDate = isoDate;
	if (typeof currentIsoDate === "string") {
		currentIsoDate = new Date(currentIsoDate);
		currentIsoDate = currentIsoDate.getHours() === 21 || 0 ? addHours(currentIsoDate, 6) : currentIsoDate;
	} else {
		currentIsoDate = currentIsoDate.getHours() === 21 || 0 ? addHours(currentIsoDate, 6) : currentIsoDate;
	}

	const parsedDate = typeof currentIsoDate === "string" ? parseISO(currentIsoDate) : currentIsoDate;

	return format(parsedDate, "dd/MM/yyyy");
}

export function formatHours(isoDate?: Date | string) {
	if (!isoDate) return "-";
	const parsedDate = typeof isoDate === "string" ? parseISO(isoDate) : isoDate;
	return format(parsedDate, "HH:mm:ss");
}

export function formatHoursDayMonthYear(isoDate?: Date | string) {
	if (!isoDate) return;
	const parsedDate = typeof isoDate === "string" ? parseISO(isoDate) : isoDate;
	return format(parsedDate, "dd/MM/yyyy - HH:mm:ss");
}

export function formatCurrencyToReal(value: number) {
	if (!value) {
		return "R$ 0,00";
	}
	const convertedValue = value / 100;
	const parseValue = convertedValue.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
	return parseValue;
}

export function summarizeText(text: string, maxCharacters = 100, trimCharactersAt = 93) {
	return text.length > maxCharacters ? text.substr(0, trimCharactersAt).trimRight() + "..." : text;
}

export function convertToInteger(value: string) {
	const newValue = parseInt(value.replace(/\D/g, ""));
	return newValue;
}

export function singularOrPluralMonth(value: string | number) {
	if (typeof value === "string") {
		value = convertToInteger(value);
	}

	return value === 1 ? `${value} Mês` : `${value} Meses`;
}

interface Field {
	kind: "value" | "date" | "time" | "status" | "text" | "icon" | string;
	multipleKeys: string[];
	aggregationFunction: Function;
	type: "boolean" | string;
	name: string;
	textIsNull: string;
}

export function translateStatus(value: any) {
	return value;
}

export function setClientStatus(status: boolean) {
	return status ? "Ativo" : "Inativo";
}

export function calculateAge(dateOfBirth: any): string | undefined {
	if (!dateOfBirth) {
		return;
	}
	const date = parseISO(dateOfBirth);
	const age = differenceInYears(new Date(), date);
	return age === 1 ? `${age} ano` : `${age} anos`;
}

export function getFieldContent(field: Field, fieldKey: string | number, fieldData: any, fieldRelationKey: string) {
	try {
		if (field.type === "boolean") {
			return field.name;
		}
		let value = fieldRelationKey ? fieldData[fieldKey][fieldRelationKey] : fieldData[fieldKey];
		switch (field.kind || fieldRelationKey) {
			case "relation":
				value = fieldData[fieldRelationKey || fieldKey];
				break;
			case "status":
				value =
					translateGender(value) || translateUserRole(value) || translateClientKind(value) || translateStatus(value);
				break;
			case "date":
				value = formatDayMonthYear(value);
				break;
			case "time":
				value = formatHours(value);
				break;
			case "dateTime":
				value = formatHoursDayMonthYear(value);
				break;
			case "value":
				if (field.textIsNull) {
					value = value ? formatCurrencyToReal(value) : field.textIsNull;
				} else {
					value = formatCurrencyToReal(value || null);
				}
				break;
			case "text":
				value = summarizeText(value);
				break;
			case "icon":
				if (field.multipleKeys) {
					const keys = field.multipleKeys.map(key => {
						return fieldData[key];
					});
					value = field.aggregationFunction(keys);
				} else {
					return value;
				}
				break;
			case "month":
				value = singularOrPluralMonth(value);
				break;
			case "age":
				value = calculateAge(value);
				break;
			case "isActive":
				value = setClientStatus(value);
				break;
			default:
				value;
				break;
		}
		return value;
	} catch {
		return "";
	}
}

export function setErrorsOnForm(headers: any[], errors: any[]) {
	errors.forEach(error => {
		if (!headers.length || !errors.length) {
			return;
		}
		headers.forEach(header => {
			const currentProperty = error.property;
			if (header.key == currentProperty) {
				header.error = error.errorMessages[0];
			}
		});
	});
}

export type InputMask = "cpf" | "cnpj" | "tel" | "birth" | "cep" | string;

export function getMask(maskType: InputMask) {
	let inputKind: string | string[] | any = maskType;
	switch (maskType) {
		case "tel":
			return (inputKind = ["(##) ####-####", "(##) #####-####"]);
		case "cpf":
		case "cnpj":
			return (inputKind = ["###.###.###-##", "##.###.###/####-##"]);
		case "rg":
			return (inputKind = ["##.###.###-#"]);
		case "birth":
		case "date":
			return (inputKind = "##/##/####");
		case "cep":
			return (inputKind = "#####-###");
		case "creditCard":
			return (inputKind = "#### #### #### ####");
		case "time":
			return (inputKind = "##:##");
		case "cvv":
			return (inputKind = "###");
		case "money":
			return (inputKind = [
				"R$ #,##",
				"R$ ##,##",
				"R$ ###,##",
				"R$ ####,##",
				"R$ #####,##",
				"R$ ######,##",
				"R$ #######,##",
			]);
		case "text":
			return (inputKind = {
				mask: "####################################################################################",
				tokens: { "#": { pattern: /./ } },
			});
		default:
			return inputKind;
	}
}

export function getAge(birthday: Date) {
	if (!birthday) {
		return 0;
	}

	birthday = new Date(birthday);
	const currentDate = new Date();

	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();
	const currentDay = currentDate.getDate();

	const birthdayYear = birthday.getFullYear();
	const birthdayMonth = birthday.getMonth();
	const birthdayday = birthday.getDate();

	let age = currentYear - birthdayYear;

	if (currentMonth < birthdayMonth || (currentMonth === birthdayMonth && currentDay < birthdayday)) {
		age--;
	}

	return age > 1 ? `${age} anos` : `${age} ano`;
}

export function isValidCPF(cpf: string) {
	cpf = cpf.replace(/[^\d]+/g, "");
	if (cpf == "") return false;
	// Elimina CPFs invalidos conhecidos
	if (
		cpf.length != 11 ||
		cpf == "00000000000" ||
		cpf == "11111111111" ||
		cpf == "22222222222" ||
		cpf == "33333333333" ||
		cpf == "44444444444" ||
		cpf == "55555555555" ||
		cpf == "66666666666" ||
		cpf == "77777777777" ||
		cpf == "88888888888" ||
		cpf == "99999999999"
	)
		return false;
	// Valida 1o digito
	let add = 0;
	for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
	let rev = 11 - (add % 11);
	if (rev == 10 || rev == 11) rev = 0;
	if (rev != parseInt(cpf.charAt(9))) return false;
	// Valida 2o digito
	add = 0;
	for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
	rev = 11 - (add % 11);
	if (rev == 10 || rev == 11) rev = 0;
	if (rev != parseInt(cpf.charAt(10))) return false;
	return true;
}

export function isValidCnpj(cnpj: string) {
	if (!cnpj) return false;

	// Aceita receber o valor como string, número ou array com todos os dígitos
	const isString = typeof cnpj === "string";
	const validTypes = isString || Number.isInteger(cnpj) || Array.isArray(cnpj);

	// Elimina valor em formato inválido
	if (!validTypes) return false;

	// Filtro inicial para entradas do tipo string
	if (isString) {
		// Limita ao máximo de 18 caracteres, para CNPJ formatado
		if (cnpj.length > 18) return false;

		// Teste Regex para veificar se é uma string apenas dígitos válida
		const digitsOnly = /^\d{14}$/.test(cnpj);
		// Teste Regex para verificar se é uma string formatada válida
		const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(cnpj);

		// Se o formato é válido, usa um truque para seguir o fluxo da validação
		if (digitsOnly || validFormat) true;
		// Se não, retorna inválido
		else return false;
	}

	// Guarda um array com todos os dígitos do valor
	const match = cnpj.toString().match(/\d/g);
	const numbers = Array.isArray(match) ? match.map(Number) : [];

	// Valida a quantidade de dígitos
	if (numbers.length !== 14) return false;

	// Elimina inválidos com todos os dígitos iguais
	const items = [...new Set(numbers)];
	if (items.length === 1) return false;

	// Cálculo validador
	const calc = (x: any) => {
		const slice = numbers.slice(0, x);
		let factor = x - 7;
		let sum = 0;

		for (let i = x; i >= 1; i--) {
			const n = slice[x - i];
			sum += n * factor--;
			if (factor < 2) factor = 9;
		}

		const result = 11 - (sum % 11);

		return result > 9 ? 0 : result;
	};

	// Separa os 2 últimos dígitos de verificadores
	const digits = numbers.slice(12);

	// Valida 1o. dígito verificador
	const digit0 = calc(12);
	if (digit0 !== digits[0]) return false;

	// Valida 2o. dígito verificador
	const digit1 = calc(13);
	return digit1 === digits[1];
}

export function isValidEmail(email: string) {
	const pattern = /\S+@\S+\.\S+/;
	return pattern.test(email);
}

export function isValidBrZip(zip: string) {
	const pattern = /^[0-9]{5}-[0-9]{3}$/;
	return pattern.test(zip);
}

export function normalize(text?: string): string {
	return text
		? text
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/gu, "")
				.toLowerCase()
		: "";
}

export function getAppointmentVisualTextArray(appointment: IAppointment): string[] {
	const lines = [];
	const { client, solution, user } = appointment;
	const clientFullName = `${client.name} ${client.lastName}`;
	const userFullName = `${user.name}${user.lastName ? " " + user.lastName : ""}`;

	const hasScheduling = appointment.scheduling?.availability.id;
	const firstPart = `${clientFullName} referiu necessidade de ${appointment.reportedNeedName}`;
	let mainText;

	if (hasScheduling) {
		const historyArray = [...appointment.scheduleUpdatesLog!];
		const creationUpdate = historyArray.splice(0, 1)[0]!;
		const date = new Date(creationUpdate.startDateTime);
		mainText = `${firstPart} e ${creationUpdate.userFullName} agendou ${solution.name} para ${format(
			date,
			"dd/MM/yyyy",
		)} às ${format(date, "HH:mm")}`;

		const updates = historyArray.map(({ userFullName, startDateTime }) => {
			const date = new Date(startDateTime);
			return `${userFullName} reagendou para ${format(date, "dd/MM/yyyy")} às ${format(date, "HH:mm")}`;
		});

		lines.push(...updates);
	} else if (appointment.need) {
		//common (no scheduling)
		const pastVerb = ["Administrativa", "Orientações"].includes(appointment.solution.category.name)
			? "orientou"
			: "indicou";
		mainText = `${firstPart} e ${userFullName} ${pastVerb} ${solution.name}`;
	} else {
		//no need
		const nounGenderInflection = client.gender === Gender.FEMALE ? "a" : "o";
		mainText = `${clientFullName} não referiu necessidade e foi orientad${nounGenderInflection} ${solution.name} por ${userFullName}`;
	}
	lines.unshift(mainText);

	return lines;
}

export enum ConnectionStatus {
	ACTIVATED = "ACTIVATED",
	CONNECTING = "CONNECTING",
	CLOSED = "CLOSED",
	FAILED = "FAILED",
}

export function formatDate(
	date: Date,
	dateStyle: "short" | "full" | "long" | "medium" | undefined = "short",
	timeStyle: "short" | "full" | "long" | "medium" | undefined = "short",
) {
	return new Intl.DateTimeFormat("pt-BR", {
		dateStyle,
		timeStyle,
		timeZone: "America/Sao_Paulo",
	}).format(new Date(date));
}

export function isIsoDate(date: string) {
	if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(date)) return false;
	return true;
}

export function isValidPhoneNumber(phoneNumber: string) {
	const regex = new RegExp("^\\(((1[1-9])|([2-9][0-9]))\\) (([0-9]{5}-[0-9]{4}))$");
	if (regex.test(phoneNumber)) {
		return true;
	}
	return false;
}
