const extraLargeSize = "text-2xl lg:text-3xl"; // 30/36px
const largeSize = "text-lg lg:text-xl"; // 20px/24px
const mediumSize = "text-sm lg:text-base"; // 14/16px
const smallSize = "text-xs lg:text-sm"; // 12/14px

export const primaryBody = [smallSize, "text-journey-black font-normal"];
export const primaryOrangeBody = [smallSize, "text-journey-orange font-normal"];
export const secondaryBody = [smallSize, "text-journey-black font-bold"];
export const secondaryOrangeBody = [smallSize, "text-journey-orange font-bold"];
export const tertiaryBody = [mediumSize, "text-journey-black font-normal"];
export const tertiaryBoldBody = [mediumSize, "text-journey-black font-bold"];

export const primaryTitle = [largeSize, "text-journey-black font-bold"];
