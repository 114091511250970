import { ListOptionsDto } from "@/interfaces/list_options.dto";
import { CrudModel } from "./_crud";

export class SolutionAppointmentAppModel extends CrudModel {
	constructor() {
		super("app/solutionAppointment");
	}
	async getAppointmentList(listDto: Partial<ListOptionsDto>) {
		return this.get("", listDto);
	}

	async getAppointmentListByDate(listDto: Partial<ListOptionsDto>) {
		return this.get("list", listDto);
	}

	async getAppointments(params: any) {
		return this.getParam(params);
	}
}

export default new SolutionAppointmentAppModel();
